/* author: Taufik Hidayah */
/* email: muhamadt84@gmail.com */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

.text-blink {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.slick-prev:before, .slick-next:before {
  color: #000000 !important;
}

/* Class for input keyword */
.rti--input {
  background-color: #F9FAFB;
}

.rti--container {
  background-color: #F9FAFB !important;
  border-color: #E4E7EB !important;
  padding: 1rem !important;
  border-radius: 0.25rem !important;
}

.rti--container:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.ck-content > blockquote,
.ck-content > dl,
.ck-content > dd,
.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6,
.ck-content > hr,
.ck-content > figure,
.ck-content > p,
.ck-content > pre {
  margin: revert;
}

.ck-content > ol,
.ck-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}

.ck-content > table {
  border-collapse: revert;
}

.ck-content > h1,
.ck-content > h2,
.ck-content > h3,
.ck-content > h4,
.ck-content > h5,
.ck-content > h6 {
  font-size: revert;
  font-weight: revert;
}

.select__control {
  padding: 4px;
}

@keyframes enter {
  0% {
      transform: scale(.9);
      opacity: 0
  }

  to {
      transform: scale(1);
      opacity: 1
  }
}

.animate-enter {
  animation: enter .2s ease-out
}

@keyframes leave {
  0% {
      transform: scale(1);
      opacity: 1
  }

  to {
      transform: scale(.9);
      opacity: 0
  }
}

.animate-leave {
  animation: leave .15s ease-in forwards
}